import React, { Component } from "react";

class Learnings extends Component {
  render() {

    return (

      <section className="section-principles mt-xl bg-alt">
        <div className="container">
          <div className="mb-l">
            <h2 className="big-header cl-black">Key <br className="no@dkt"></br>learnings</h2>
            <p className="big-description">
              During the years I've understood that Design is not linear, but with a solid team process it´s easy to get better solutions. Here some notes for myself about processes.
            </p>
          </div>
          <div className="grid">

            <div className="grid__item width-4/12 width-12/12@m">
              <h5 className="item-headline alt-font"><span role="img" aria-label="brain">🧠</span> Start with a problem</h5>
              <p>Discover and prioritize new problems before they even appear. Deeply understand them. Ask questions. Frame them interestingly.</p>
            </div>
            <div className="grid__item width-4/12 width-12/12@m">
              <h5 className="item-headline alt-font"><span role="img" aria-label="light">✨</span> Create hypothesis</h5>
              <p>Using data can help to decide where to focus (and where to not focus).  Pick data sets that are different. Talk to people.</p>
            </div>
            <div className="grid__item width-4/12 width-12/12@m">
              <h5 className="item-headline alt-font"><span role="img" aria-label="notes">📝</span> Propose solutions</h5>
              <p>Transform problems into oportunities. Show a few crazy ways to solve the problem. Involve different roles, win alignments. Balance business & user needs.</p>
            </div>
            <div className="grid__item width-4/12 width-12/12@m">
              <h5 className="item-headline alt-font"><span role="img" aria-label="test">🔬</span> Test</h5>
              <p>Prototype the solution & test it with users. Learn. Iterate. Repeat. Validate your hypothesis. Don't fall in love with them.</p>
            </div>
            <div className="grid__item width-4/12 width-12/12@m">
              <h5 className="item-headline alt-font"><span role="img" aria-label="ship">🚢</span> Build, ship & learn</h5>
              <p>Focus on systems thinking. Reuse, evolve. Own. Measure how success should look like. Learn about it, write it, share it.</p>
            </div>
            <div className="grid__item width-4/12 width-12/12@m">
              <h5 className="item-headline alt-font"><span role="img" aria-label="chocolate">🍫</span> Add chocolate</h5>
              <p>It's ok to be boring, try to be good. Go crazy if you can, sometimes if it's fun it's memorable.</p>
            </div>

          </div>
        </div>
      </section>

    );
  }
}

export default Learnings;
